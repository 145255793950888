@font-face {
  font-family: 'Brandon-Grotesque';
  src: local('Brandon-Grotesque'),
    url('./common-code-dashboards/fonts/Brandon-Grotesque/BrandonGrotesque-Regular.otf') format('truetype');
  font-weight: normal;
}
:root {
  --doc-height: 100%;
 }
 
 html,
 body {
  padding: 0;
  margin: 0;
  overflow: hidden;
  width: 100vw;
  height: 100vh;
  height: var(--doc-height);
 }

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
